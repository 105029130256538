import { Col, Row } from 'antd'
import { H3, H3Bold, TextSmall } from '../components/shared/typography'
import React, { useState } from 'react'

import { BtnSecoundary } from '../components/shared/button'
import Seo from '../components/Seo'
import { StyledContent } from '../components/layout/layout'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

const HtmlContainer = styled.div`
  h1,
  h2,
  h3 {
    font-weight: bold;
  }
  font-size: 16px;
  color: white;
`

const StyledForm = styled.form`

  label {
    font-weight: 600;
    font-size: 1rem;
  }
  input,
  textarea {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px 12px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-color: transparent;
    color: inherit;
    text-align: inherit;
    height: auto;
    border-style: none;
    border: 1px solid grey;
    border-radius: 4px;

    &:selection {
      border-style: none;
      border: 1px solid grey;
    }
  }
`

const WineTaste: React.FC = ({ data: { collection } }: any) => {
  const [success, setSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')
  const [mobile, setMobile] = useState('')
  const [persons, setPersons] = useState('')
  const [date, setDate] = useState('')


  function encode(data: any) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const form = e.target
    fetch('https://getform.io/f/d8fe3394-077a-4822-9813-02d3a002e0ea', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        navn: name,
        email: email,
        telefon: mobile,
        antal: persons,
        dato: date,
        besked: msg,
      }),
    })
      .then(() => {
        setTimeout(() => {
          setSuccess(true)
        }, 1000)
      })
      .catch((error) => {
        setTimeout(() => {
          setIsError(true)
        }, 1000)
      })
  }

  return (
    <>
      <Seo
        title={'Vinsmagning'}
        image={collection.nodes[0]?.image?.originalSrc}
        keywords={['vinsmagning', 'København', 'rødvin', 'hvidvin', 'rosé', 'tapas', 'vinsmagning københavn', 'eksklusiv', 'mansted wine', 'Mansted Wine vinsmagning']}
      />
      <div className="relative isolate overflow-hidden bg-gray-900 py-12 sm:py-12">
      <img
        src="https://cdn.shopify.com/s/files/1/0582/2834/2969/files/mansted-wine-showroom-2.webp?v=1699745083"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover opacity-10"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/3 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Vinsmagninger</h2>
          <HtmlContainer dangerouslySetInnerHTML={{ __html: collection.nodes[0].descriptionHtml }} />

        </div>
      </div>
    </div>

    <StyledContent>
    <div className="relative bg-white my-16">
      <div className="lg:absolute lg:inset-0 lg:left-1/2">
        <img
          className="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full"
          src="https://cdn.shopify.com/s/files/1/0582/2834/2969/files/mansted-wine-showroom.webp?v=1699745083&ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80"
          alt=""
        />
      </div>
      <div className="pb-24 pt-16 sm:pb-32 sm:pt-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-32">
        <div className="px-6 lg:px-8">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Kontakt for mere information</h2>

      <StyledForm name="Vin smagning" method="POST" onSubmit={handleSubmit}>
        <Row gutter={40}>
      {success && <H3 style={{color: 'black'}}>{'Tak for din besked! Vi vender tilbage hurtigst muligt!'}</H3>}
          {isError && (
            <H3Bold style={{color: 'red'}}>
              {'Der skete en fejl - prøv igen!'}
            </H3Bold>
          )}
          <Col xs={24} md={24}>
            <label htmlFor="name">Navn*</label>
            <input id="name" name="name" required type="text" onChange={(e) => setName(e.target.value)} value={name} disabled={success} />
            <label htmlFor="email">E-mail*</label>
            <input id="email" name="email" type="email" onChange={(e) => setEmail(e.target.value)} value={email} disabled={success} />
            <label htmlFor="phone">Telefon*</label>
            <input id="phone" name="phone" type="tel" required onChange={(e) => setMobile(e.target.value)} value={mobile} disabled={success} />
          </Col>
          <Col xs={24} md={24}>
            <label htmlFor="persons">Antal Personer (minimum 8)*</label>
            <input id="persons" required name="persons" type={"number"} min={8} onChange={(e) => setPersons(e.target.value)} value={persons} disabled={success} />
            <label htmlFor="date">Ønsket dato</label>
            <input id="date" name="date" type="date" onChange={(e) => setDate(e.target.value)} value={date} disabled={success} />
            <label htmlFor="msg">Kommentar</label>
            <textarea id="msg" name="msg" placeholder="Ønsket lokation, tilkøb, spørgsmål etc." onChange={(e) => setMsg(e.target.value)} value={msg} disabled={success} />
          </Col>
        </Row>
        <TextSmall>
          <BtnSecoundary disabled={success} type="submit">
            Send
          </BtnSecoundary>
        </TextSmall>
      </StyledForm>
      </div>
        </div>
      </div>
      </div>
      </StyledContent>
    </>
  )
}
export default WineTaste

export const query = graphql`
  query {
    collection: allShopifyCollection(filter: { title: { eq: "Smagninger" } }) {
      nodes {
        description
        descriptionHtml
        title
        image {
          gatsbyImageData(placeholder: BLURRED, width: 600)
          originalSrc
        }
      }
    }
  }
`
